<template>
  <div>
    <vs-card>
      <vs-row>
        <vs-col vs-w="12">
          <vs-table search :data="users">
            <template slot="header">
              <h3>
                Leaves Requests
              </h3>
            </template>
            <template slot="thead">
              <vs-th>Name</vs-th>
              <vs-th>Team</vs-th>
              <vs-th style="background: #f3f3f3;">Requested On</vs-th>
              <vs-th>From</vs-th>
              <vs-th>To</vs-th>
              <vs-th>Reason</vs-th>
              <vs-th>Status</vs-th>
              <vs-th style="">Action</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].userName">
                  {{ data[indextr].user_name }}
                </vs-td>

                <vs-td :data="data[indextr].teamName">
                  {{ data[indextr].team_name }}
                </vs-td>
                <vs-td style="background: #f3f3f3;">
                  {{ formatDate(data[indextr].created_at) }}
                </vs-td>

                <vs-td :data="data[indextr].leave_from_date">
                  {{ unixTimestampConvert(data[indextr].leave_from_date) }}
                </vs-td>
                <vs-td :data="data[indextr].leave_to_date">
                  {{ unixTimestampConvert(data[indextr].leave_to_date) }}
                </vs-td>
                <vs-td :data="data[indextr].leave_reason" style="width: 25%;">
                  {{ data[indextr].leave_reason }}
                </vs-td>
                <vs-td :data="data[indextr].status">
                  <div
                    v-if="data[indextr].status === 'approved'"
                    style="text-align: center; "
                  >
                    <vs-chip color="success">Approved</vs-chip>
                  </div>
                  <div
                    v-if="data[indextr].status === 'rejected'"
                    style="text-align: center; "
                  >
                    <vs-chip color="danger">Rejected</vs-chip>
                  </div>
                  <div
                    v-if="data[indextr].status === 'pending'"
                    style="text-align: center; "
                  >
                    <vs-chip color="warning">Pending</vs-chip>
                  </div>
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <vs-row v-if="data[indextr].reportee === true">
                    <vs-col vs-type="flex" vs-w="12">
                      <vs-button
                        size="small"
                        radius
                        color="success"
                        type="gradient"
                        icon="done"
                        @click="submitLeaveStatus('approved', data[indextr].id)"
                      ></vs-button>
                      <vs-button
                        size="small"
                        radius
                        color="danger"
                        type="gradient"
                        icon="block"
                        @click="submitLeaveStatus('rejected', data[indextr].id)"
                      ></vs-button>
                    </vs-col>
                  </vs-row>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-col>
      </vs-row>
      <!-- <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
          <vs-chip color="primary" style="margin-right: 2%">
            <b>{{ countsleads }}</b>
          </vs-chip>
          <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
        </vs-col>
      </vs-row> -->
    </vs-card>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
require("vue-simple-calendar/static/css/default.css");

import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
import constants from "../../constants.json";

export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
  },
  data() {
    return {
      team_id: "",
      Allusers: [],
      users: [],
      teamList: [],
      // tablelinks: 0,
      // currentTablePage: 1,
      // countsleads: 0,
    };
  },
  watch: {},

  computed: {},
  mounted() {
    this.getLeaves();
    // this.getAllUsers();
    // this.myprofile();
    //   this.getUserName(2);
  },
  methods: {
    getAllUsers() {
      let url = `${constants.SERVER_API}getAllUsers`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("users list", response.data.data);
          this.Allusers = response.data.data;
          this.getTeams();
          // let obj = response.data.data.find(team => team.id === this.team_id);
          // console.log('team list',obj.name)
          // this.teamName = obj.name;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    formatDate(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    getUserName(user_id) {
      let user = this.Allusers.find((user) => user.id === user_id);
      console.log("user ,,,,,,,,", user_id);
      return user.first_name + " " + user.last_name;
    },
    getTeamName(user_id) {
      let user = this.Allusers.find((user) => user.id === user_id);
      return user.team;
    },
    getLeaves() {
      let url = `${constants.MILES_CM_BACK}leave`;
      // this.uploadBoolean = false;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.users = response.data.data;
          // this.currentTablePage = response.data.current_page;
          // this.tablelinks = response.data.last_page;
          // console.log("get leaves list", response.data.data);
          // let leavesData = response.data.data;
          // this.users = [];
          // leavesData.forEach((element) => {
          //   let getleaveappliedDate = moment(element.created_at).format();
          //   let userName = this.getUserName(element.user_id);
          //   let teamName = this.getTeamName(element.user_id);
          //   this.users.push({
          //     leave_id: element.id,
          //     id: element.user_id,
          //     teamid: element.team_id,
          //     requestedOn: moment(getleaveappliedDate).format("ll"),
          //     from: element.leave_from_date,
          //     to: element.leave_to_date,
          //     userName: userName,
          //     teamName: teamName,
          //     appliedOn: moment(getleaveappliedDate).format("ll"),
          //     leave_from_date: moment(element.leave_from_date).format("ll"),
          //     leave_to_date: moment(element.leave_to_date).format("ll"),
          //     leave_reason: element.leave_reason,
          //     status: element.status,
          //   });
          // });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getTeams() {
      let url = `${constants.SERVER_API}team`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("team list", response.data.data);
          this.teamList = response.data.data;
          // let obj = response.data.data.find(team => team.id === this.team_id);
          // console.log('team list',obj.name)
          // this.teamName = obj.name;
          this.getLeaves();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    // myprofile() {
    //   let url = `${constants.SERVER_API}getUserProfile`;
    //   axios
    //     .get(url, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       console.log("My profile", response.data.data);
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },

    submitLeaveStatus(status, id) {
      let obj = {
        status: status,
      };

      let url = `${constants.MILES_CM_BACK}leave/${id}`;

      axios
        .put(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.handleNotification(response);
          this.getLeaves();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
<style>
.vs-con-table {
  width: 100%;
}
th .vs-table-text {
  /* justify-content: center; */
}
td {
  /* text-align: center; */
}
</style>
